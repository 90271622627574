<template>
  <v-container fluid>
    <alarm-form v-if="alarm != null" :edit="true" :alarm="alarm"></alarm-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AlarmForm from "./AlarmForm.vue";
export default {
  components: {
    AlarmForm,
  },

  data() {
    return {
      alarm: null,
    };
  },

  computed: {
    ...mapGetters("alarms", ["getAlarmById"]),
  },

  methods: {
    ...mapActions("alarms", ["getAlarms"]),
  },

  async created() {
    let id = this.$route.params.id;
    if (!id) this.$router.push("/alarms");

    await this.getAlarms();

    // We arange the silos so we have them in the correct format for the alarm form
    let tempAlarm = this.getAlarmById(id);
    tempAlarm.silos = tempAlarm.silos.map((d) => d.siloId);
    this.alarm = tempAlarm;
  },
};
</script>